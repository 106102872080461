<template>
    <ValidationObserver ref="observe">
        <b-form>
            <!--form add notification-->
            <ek-dialog
                ref="dialog"
                title="إضافة دورة"
                size="md"
                placeholder="ابحث عن دورة محددة"
                btnText=" دورة جديدة"
                @ok="submit()"
            >
                <template #body>
                    <validationObserver ref="dashUserForm">
                        <label> نوع الدورة :</label>
                        <b-form-radio-group
                            v-model="previousExamDto.isFree"
                            :options="options"
                            value-field="item"
                            text-field="name"
                        ></b-form-radio-group>
                        <ek-input-text
                            v-model="previousExamDto.name"
                            :rules="[
                                { type: 'required', message: 'العنوان مطلوب' },
                            ]"
                            name="title"
                            placeholder="ادخل اسم الدورة"
                            label="اسم الدورة"
                        ></ek-input-text>
                        <ek-input-select
                            v-model="previousExamDto.universityId"
                            :options="universityList"
                            name="الجامعة"
                            :label="
                                $route.query.tab === '0'
                                    ? ' تابع للجامعة '
                                    : ' تابع للمرحلة  التعليمية'
                            "
                        >
                        </ek-input-select>
                        <ek-input-select
                            :label="
                                $route.query.tab === '0'
                                    ? 'تابع للكلية'
                                    : ' تابع للصف'
                            "
                            :options="[...facultyList]"
                            name="select"
                            v-model="previousExamDto.facultyId"
                            :clearable="true"
                        />
                        <ek-input-select
                            v-model="previousExamDto.semesterId"
                            label="الفصل"
                            placeholder="اختر الفصل "
                            :options="semesters"
                            name="select"
                            :clearable="true"
                        />
                        <ek-input-select
                            v-model="previousExamDto.subjectId"
                            :options="subjectsNames"
                            name="المادة"
                            label=" تابع لمادة "
                            placeholder="تابع ل سنة - فصل - مادة "
                        >
                        </ek-input-select>
                        <ek-input-text
                            v-model="previousExamDto.year"
                            label="السنة"
                            placeholder="حدد السنة "
                            name="select"
                            :clearable="true"
                            type="number"
                        />
                        <ek-input-select
                            v-model="previousExamDto.teacherId"
                            :options="settingDto.teachers"
                            clearable
                            placeholder="الأستاذ"
                            name="الأستاذ"
                            label="الأستاذ"
                        />

                        <ek-input-select
                            v-model="previousExamDto.unitId"
                            :options="unitNames"
                            name="الوحدة"
                            label=" اسم الوحدة "
                            placeholder="تابع للوحدة "
                        >
                        </ek-input-select>
                        <ek-input-select
                            v-model="previousExamDto.chapterId"
                            :options="chapterList"
                            name="القسم"
                            label=" اسم القسم "
                            placeholder="تابع للقسم "
                        >
                        </ek-input-select>
                    </validationObserver>
                </template>
            </ek-dialog>
        </b-form>
    </ValidationObserver>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
    components: {
        ValidationObserver,
    },
    computed: {
        ...mapState({
            previousExamDto: (state) => state.previousExams.previousExamDto,
            hello: (state) => state.previousExams.hello,
            semesters: ({ settings }) => settings.semesterList,
            settingDto: ({ settings }) => settings.settingDto,
            years: ({ settings }) => settings.yearList,
            helloSub: ({ subjects }) => subjects.helloSub,
            subjectsNames: ({ subjects }) => subjects.subjectsNames,
            unitNames: (state) => state.unit.unitNames,
            universityList: (state) => state.university.universityList,
            facultyList: (state) => state.faculty.facultyList,
            chapterList: ({ chapter }) => chapter.chapterList.chapters,
        }),
        ...mapGetters(["getSubjectsNamesGetter"]),
    },
    data() {
        return {
            selecte: [],
            allSelected: false,
            selected: 0,
            options: [
                { item: true, name: "مجاني" },
                { item: false, name: "مدفوع" },
            ],
        };
    },

    methods: {
        ...mapActions([
            "getSettings",
            "getSettingYear",
            "getStudentsName",
            "getSettingSemester",
            "addPreviousExam",
            "getUnitNames",
            "getPreviousExams",
        ]),
        ...mapMutations(["Reset_Dto"]),

        submit() {
            this.$refs.dashUserForm.validate().then((success) => {
                if (success) {
                    this.addPreviousExam({
                        ...this.previousExamDto,
                        PreviousExamType: "PreviousExam",
                    }).then(() => {
                        this.$refs.dialog.close();
                        this.Reset_Dto();
                        this.getPreviousExams({
                            PreviousExamType: "PreviousExam",
                        });
                    });
                }
            });
        },
    },
    created() {
        this.getSettingYear();
        this.getSettingSemester();
        this.getSettings();
        this.getUnitNames("1");
    },
};
</script>
